/**************************************************************************
/*-------------------------- Common Styling -------------------------------
/*************************************************************************/

.position-relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.color-primary {
  color: #154f56;
}

.color-secondary {
  color: #f4bd06;
}

.input-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.hr-pad {
  padding-top: 5.3125rem !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 46px;
}

.MuiInput-root:hover::before {
  border-bottom: 2px solid #154f56 !important;
}

.MuiInputLabel-root.Mui-focused {
  color: #154f56 !important;
}

.MuiInput-root::after {
  border-bottom: 2px solid #f4bd06 !important;
}

.MuiInput-root.Mui-focused::before {
  border-bottom: 2px solid #f4bd06 !important;
}

.header-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header-logo p {
  font-size: 20px;
  font-weight: 500;
  color: #154f56;
}

.header-logo img {
  width: 50px;
}

.header-logo span {
  color: #f4bd06;
}

.header-user-icon img {
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  padding: 5px;
  background-color: #167c92;
}

.bg-white {
  background-color: #ffffff;
}

.bg-light {
  background-color: #f4f5f8;
}

.bg-gray {
  background-image: linear-gradient( 180deg, #ffffff 0%, #f4f5f8 100%);
}

.bg-blue {
  background-color: #4743db !important;
}

.bg-spray {
  background-color: #65efe3 !important;
}

.bg-coral {
  background-color: #fa8557 !important;
}

.bg-red {
  background-color: #f71e3f !important;
}

.bg-orange {
  background-color: #fa5f1c !important;
}

.bg-yellow {
  background-color: #ffb300 !important;
}

.bg-turquoise {
  background-color: #50D7E5 !important;
}

.bg-green {
  background-color: #167c92 !important;
}

.bg-green-opacity-2 {
  background-color: rgba(0, 176, 116, 0.2);
}

.text-green {
  color: #167c92 !important;
}

.bg-yellow-2-opacity-2 {
  background-color: rgba(250, 207, 50, 0.2);
}

.text-yellow-2 {
  color: #facf32 !important;
}

.bg-red-opacity-2 {
  background-color: rgba(247, 30, 63, 0.2);
}

.text-red {
  color: #f71e3f !important;
}

.section-top {
  position: relative;
  padding-bottom: 8.4375rem;
  padding-top: 5.9375rem;
}

.form-inner {
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgb(130 130 130 / 16%);
  padding: 1.875rem;
  background-color: #fff;
}

.main {
  padding-top: 35px;
  padding-left: 250px;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-none:hover {
  cursor: pointer;
}

.custom-link {
  fill: #0f5763 !important;
  text-decoration: none;
  transition: all .3s ease;
}

.custom-link:hover {
  cursor: pointer;
  fill: #fbbf04 !important;
}
.modal-status {
  right: 40px;
  top: 20px;
  text-transform: capitalize;
  position: absolute;
}
.badge {
  display: inline-block;
  padding: 0.2rem 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.badge.badge-default {
  background-color: #edeff1;
  color: #4d555f;
  border-color: #4d555f;
}

.badge.badge-primary {
  background-color: #cdf0d7;
  color: #2b9348;
  border-color: #2b9348;
}

.badge.badge-yellow {
  background-color: #fef5d5;
  color: #e8b906;
  border-color: #e8b906;
}

.badge.badge-orange {
  background-color: #fef0ea;
  color: #f86624;
  border-color: #f86624;
}

.badge.badge-red {
  background-color: #fdeeef;
  color: #ea3546;
  border-color: #ea3546;
}

.badge.badge-purple {
  background-color: #e4d5f3;
  color: #662e9b;
  border-color: #662e9b;
}

.badge.badge-blue {
  background-color: #e4f5f8;
  color: #43bccd;
  border-color: #43bccd;
}

/******************************************************************************
/*-------------------------- Home Page Styling --------------------------------
/*****************************************************************************/

.main-section {
  background: #EDF8F5 !important;
}

.banner-section {
  background-image: url(./_assets/globe-pattern.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.banner-left {
  padding: 12rem 0;
}

.banner-text-top {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  color: #167c92 !important;
  margin-bottom: 1.25rem !important;
}

.banner-heading {
  font-size: 3.75rem;
  line-height: 1.2;
  letter-spacing: -0.9px;
  color: #2b3940 !important;
  margin-bottom: 1.875rem !important;
  margin-top: 0;
}

.banner-paragraph {
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  font-size: 1.125rem;
  color: #6b6e6f;
}

/***************************** Search Form Styling **********************************/

.search-section {
  position: relative;
}

.search-box {
  position: absolute;
  transform: translateY(-50%);
  background-color: #ffffff;
  width: 100%;
}

.search-form {
  padding: 1.25rem 1.5625rem !important;
  border-radius: 5px;
  box-shadow: 0 29px 75px rgb(156 156 156 / 16%);
}

.search-input-box {
  display: flex;
  align-items: center;
}

.search-input-icon {
  color: #154f56;
  margin-right: 15px;
}

/***************************** Top Companies Styling **********************************/

.top-companies {
  padding-top: 9.6875rem !important;
  padding-bottom: 5.9375rem !important;
}

.companies-heading {
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  color: #6b6e6f !important;
  font-weight: 400 !important;
}

.company-box {
  margin: 1rem 0.75rem;
}

.company-box img {
  opacity: 0.5;
  transition: 0.3s all ease;
}

.company-box:hover img {
  opacity: 1;
}

/**************************** Categories Styling *************************************/

.section-heading-center {
  text-align: center;
  margin-bottom: 4.375rem !important;
}

.main-heading {
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: -0.48px;
  font-weight: 700 !important;
  margin-bottom: 1.5625rem !important;
  color: #2b3940;
}

.heading-text {
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  margin-bottom: 1rem !important;
  padding-right: 3.75rem !important;
  color: #6b6e6f;
}

.category-box {
  transition: .4s;
  border-radius: 10px;
  text-align: center;
  padding-top: 2.1875rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 0.375rem !important;
  margin-bottom: 1.875rem !important;
  background-color: #fff !important;
  border: 1px solid #f0f0f0;
}

.category-box:hover {
  cursor: pointer;
  border: 1px solid transparent !important;
  box-shadow: 0 40px 60px rgb(0 0 0 / 8%);
}

.category-box-square {
  border-radius: 10px;
  max-width: 70px;
  min-width: 70px;
  max-height: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem !important;
  color: #fff !important;
}

.category-box-heading h5 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  color: #2b3940 !important;
  margin-bottom: 0.5rem;
}

.category-box-heading p {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  font-weight: 400 !important;
  color: #6b6e6f !important;
  margin-bottom: 1rem;
}

/****************************** About us Styling ***************************************/

.about-us {
  background-color: #ffffff;
  padding-top: 9.6875rem !important;
  padding-bottom: 9.6875rem !important;
}

.about-img {
  position: relative;
  padding-right: 5.3125rem;
}

.about-img img {
  width: 100%;
}

.job-media {
  max-width: 311px;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 17px 26px 99px rgb(114 114 114 / 16%);
  margin-bottom: 4.6875rem !important;
  padding-right: 2.5rem !important;
  padding-top: 1.5625rem !important;
  padding-left: 1.25rem !important;
  padding-bottom: 0.25rem !important;
  border-radius: 0.625rem !important;
  background-color: #fff !important;
}

.media-alert {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.75rem !important;
}

.media-alert-icon {
  max-width: 41px;
  min-width: 41px;
  max-height: 41px;
  min-height: 41px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #facf32 !important;
}

.media-body {
  flex: 1;
  padding-left: 0.75rem !important;
}

.media-body h6 {
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.26px;
  color: #167c92 !important;
  text-transform: uppercase !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.media-body p {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  color: #2b3940 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.about-right {
  padding-left: 2.5rem !important;
  padding-right: 2.1875rem !important;
}

.about-heading {
  font-size: 2.5rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
  margin-bottom: 3.75rem !important;
  margin-top: 0;
  font-weight: 700;
}

.about-content {
  margin-bottom: 2.5rem !important;
  display: flex;
  align-items: flex-start;
}

.about-content-icon {
  border-radius: 5px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.about-content-body {
  flex: 1;
  padding-left: 1.25rem !important;
}

.about-content-body h5 {
  margin-bottom: 0.75rem !important;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1.2;
  color: #2b3940;
  margin-top: 0;
}

.about-content-body p {
  color: #6b6e6f;
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  margin-top: 0;
  margin-bottom: 0 !important;
}

/************************************ Featured Jobs Styling ******************************************/

.featured-jobs {
  background-color: #F4F5F8 !important;
  padding-top: 6.875rem !important;
  padding-bottom: 7.5rem !important;
}

.featured-job-box {
  background-color: #ffffff;
  transition: .4s;
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
  padding-top: 1.875rem !important;
  padding-bottom: 1.25rem !important;
  border-radius: 0.625rem !important;
  margin-bottom: 1.5625rem !important;
}

.featured-job-box:hover {
  box-shadow: 0 40px 60px rgb(0 0 0 / 8%);
}

.job-type {
  display: flex;
  align-items: center;
}

.job-box-square {
  max-width: 72px;
  min-width: 72px;
  max-height: 72px;
  min-height: 72px;
  align-items: center;
  justify-content: center;
  margin-right: 1.5625rem !important;
  display: block;
}

.job-box-square {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.job-headings h3 {
  margin-bottom: 0 !important;
  margin-top: 0;
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1.2;
  color: #2b3940;
}

.job-title {
  font-size: 1.3125rem;
  line-height: 1.62;
  letter-spacing: -0.21px;
  color: #2b3940;
  text-decoration: none;
  transition: .4s;
}

.job-title:hover, .company-title:hover {
  color: #fbbf04;
}

.company-title {
  color: #6b6e6f;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.26px;
  text-decoration: none;
  transition: .4s;
}

.job-salary {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end !important;
}

.job-salary-icon {
  margin-right: 0.75rem !important;
  margin-top: 0.375rem !important;
}

.job-price {
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.24px;
  color: #adb4b7 !important;
  font-weight: 700 !important;
  margin-top: 0;
  margin-bottom: 0 !important;
}

.job-price span {
  color: #2b3940 !important;
}

.tags-list {
  margin-right: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 1rem;
}

.tags-list li a {
  text-decoration: none;
  min-width: 96px;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.26px;
  border-radius: 3px;
  background-color: rgba(176, 213, 232, 0.15);
  text-align: center !important;
  color: #2b3940 !important;
  padding: 0.75rem 1rem;
  margin-right: 0.5rem;
  margin-top: 0.375rem;
}

.job-details {
  margin-right: -1.5625rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 0;
  list-style: none;
}

.job-details li {
  display: flex;
  font-size: 13px;
  color: #2b3940 !important;
  margin-right: 1.5625rem;
  margin-top: 0.375rem;
}

.job-details li span {
  margin-top: -2px;
  margin-right: 0.625rem !important;
  font-weight: 600;
}

/********************** Best Applicants Styling *****************************/

.best-applicants {
  position: relative;
  background-color: #167c92;
}

.absolute-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.best-applicants-content {
  text-align: left;
  padding-right: 2.1875rem;
}

.best-applicants-sub-heading {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  color: #fff;
  margin-bottom: 1.5625rem;
}

.best-applicants-heading {
  font-size: 2.5rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
  color: #fff;
  margin-bottom: 1.5625rem;
  font-weight: 700;
  margin-top: 0;
}

.best-applicants-text {
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  margin-bottom: 2.8125rem;
  margin-top: 0;
}

.best-applicants-btn {
  height: 60px;
  justify-content: center;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 700;
  min-width: 130px;
  line-height: 28px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  color: #2b3940;
  text-transform: uppercase;
  width: 180px;
  background-color: #fff;
  border-color: #fff;
  text-decoration: none;
  transition: .4s;
}

.best-applicants-btn:hover {
  color: #0c1012;
  background-color: #ececec;
  border-color: #e6e6e6;
}

/** Applicants Gallery **/

.best-applicants-gallery {
  width: 50%;
  margin-left: auto;
}

.gallery-images {
  display: flex;
  flex-wrap: wrap;
}

.gallery-item {
  max-width: 20%;
  width: 20%;
}

.gallery-item img {
  width: 100%;
  height: 100%;
}

/******************************************************************************
/*-------------------------- Contact Page Styling -----------------------------
/*****************************************************************************/

.form-heading {
  color: #2b3940;
  text-align: center;
  font-size: 2.5rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
}

.contact-details {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #6b6e6f;
}

.contact-details .icon {
  margin-right: 10px;
}

.contact-details .text {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
}

.sidebar-btn {
  cursor: pointer;
  display: none;
  min-height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  z-index: 801;
  background: #167c92;
  color: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.sidebar-post {
  padding: 1.5rem;
  text-align: center;
  margin: 1rem auto;
  width: 85%;
  min-width: 180px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  background-color: #167c92;
  border-color: #167c92;
  text-transform: uppercase;
  border: 1px solid transparent;
  transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.sidebar-post:hover {
  color: #fff;
  background-color: #fbbf04;
  border-color: #fbbf04;
}

.sidebar-link {
  text-decoration: none;
  color: #6b6e6f;
  position: relative;
  background-color: #167c92;
  display: block;
  margin: 5px 0;
}

.sidebar-link span {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  font-weight: 600;
}

.sidebar-link svg {
  transition: .4s;
}

.sidebar-link:hover svg {
  color: #167c92;
}

.sidebar-link .sidebar-item {
  transition: .4s;
  background-color: #ffffff !important;
}

.sidebar-item:hover, .sidebar-item.Mui-selected {
  margin-left: 5px;
}

.sidebar-item.Mui-selected svg {
  fill: #167c92;
}

.sidebar-notify {
  font-size: 14px !important;
  position: absolute;
  right: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px !important;
  background: #f3fcff;
  text-align: center;
}

/******************************************************************************
/*-------------------------------- Dashboard Styling --------------------------
/*****************************************************************************/

.dashboard-heading {
  font-size: 1.75rem;
  line-height: 1.62;
  letter-spacing: -0.21px;
  margin-top: 0;
  color: #2b3940;
}

.dashboard-cards {
  margin-bottom: 2rem;
}

.dashboard-table {
  margin-bottom: 3.4375rem;
}

.table-header-row {
  padding: 25px;
  margin-bottom: 1.5rem;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px rgb(130 130 130 / 16%);
}

.table-heading {
  font-size: 1.7125rem;
  line-height: 1.62;
  letter-spacing: -0.21px;
  margin: 0;
  color: #2b3940;
}

.table-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.table-filter .label {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  padding: 0.375rem;
  margin: 0 1rem 0 0;
  color: #6b6e6f;
}

.table-filter .field {
  height: 48px;
}

.table-filter .field select {
  border-color: rgba(0, 176, 116, 0.22);
  min-width: 273px;
  outline: none;
  font-weight: 600;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  font-size: 14px;
  padding: 0 1.25rem;
  color: #2b3940;
  height: 100%;
}

.dashboard-table .table {
  padding: 1.5625rem 2.5rem;
  border-radius: 0.625rem;
  background-color: #fff;
  box-shadow: 2px 2px 4px rgb(130 130 130 / 16%);
}

/**************************** Profile Page Styling ***************************/

.basic-info {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 35px rgb(178 178 178 / 16%);
}

.basic-info .basic-upper {
  text-align: center;
  padding: 2.5rem 0.75rem;
  /* border-bottom: 1px solid #e5e5e5 !important; */
}

.profile-img {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.625rem;
  text-align: center;
}

.profile-img img {
  display: inline-block;
  border-radius: 50%;
  max-width: 100%;
  padding: 10px;
  background-color: #167c92;
}

.profile-name {
  color: #2b3940;
  font-size: 1.3125rem;
  line-height: 1.62;
  letter-spacing: -0.21px;
  font-weight: 600;
  margin: 0;
}

.basic-info .basic-upper .occupation {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  color: #6b6e6f;
  margin: 0;
}

.basic-info .basic-lower {
  padding: 1.875rem;
}

.basic-lower .lower-heading-main {
  margin-top: 0;
  margin-bottom: 1.5625rem;
  color: #2b3940;
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
}

.basic-lower .lower-text-heading {
  margin-bottom: 1.25rem
}

.basic-lower .lower-text-heading p {
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  margin: 0;
  color: #6b6e6f;
}

.basic-lower .lower-text-heading h5 {
  margin: 0;
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: -0.08px;
  color: #2b3940;
}

.basic-lower .lower-text-heading a {
  color: #2b3940;
  text-decoration: none;
}

.basic-lower .lower-text-heading a:hover {
  color: #0c1012
}

/************************************ Jobs Detalis Styling ******************************************/

.job-header {
  padding-bottom: 1.85rem;
  margin-bottom: 1.85rem;
  border-bottom: 1px solid #e5e5e5;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left img {
  max-width: 75px;
  width: 100%;
}

.job-heading {
  margin-left: 15px;
}

.job-heading h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: -0.21px;
  color: #2b3940;
}

.job-heading p {
  margin: 0;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.26px;
  color: #6b6e6f;
}

.header-right {
  color: #2b3940;
  text-align: right;
}

.job-center {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1.35rem;
  margin-bottom: 1.85rem;
}

.icon-with-text {
  display: flex;
  align-items: center;
}

.icon-with-text img {
  width: 20px;
  margin-right: 10px;
}

.job-text-bold {
  text-transform: capitalize;
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  font-weight: 600;
  color: #2b3940;
}

.skills {
  padding: 0;
  margin-top: 5px;
}

.skills li {
  display: inline-block;
  list-style-type: none;
  font-size: 13px;
  line-height: 2;
  padding: 0.5rem 0.75rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  letter-spacing: 0.26px;
  border-radius: 3px;
  color: #2b3940;
  background-color: rgba(176, 213, 232, 0.15);
}

.job-branch-details {
  position: absolute;
  right: 50px;
  top: 0;
  box-shadow: 0 0 30px -10px #cdcdcd;
  padding: 10px 15px;
}

.job-branch-details h4 {
  margin: 0;
  border-bottom: 1px dashed #cdcdcd;
  margin-bottom: 5px;
}

.job-branch-details .branch-name {
  font-size: 14px;
  font-weight: 600;
}

.job-branch-details p {
  font-size: 14px;
  margin: 0;
}

/****************** Chat message box styling ******************/

.chat-to-right {
  text-align: right;
}

.chat-message {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px 5px 5px 0px;
  color: #000;
  background-color: #e6e7ec;
  max-width: 600px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  word-break: break-all;
  direction: ltr;
}

.chat-message-own {
  color: #fff;
  margin-left: auto;
  background-color: #167c92;
  border-radius: 5px 5px 0px 5px;
}

.message-main {
  position: relative;
  margin: 5px 0;
}

.message-main .date, .message-main .sender {
  font-size: 12px;
}

/****************** Tabs box styling ******************/

.tabs-list {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.tabs-list button {
  font-weight: 600;
  text-transform: capitalize;
}

.tabs-list button.Mui-selected {
  color: #167c92;
}

.tabs-list .css-1aquho2-MuiTabs-indicator {
  background-color: #167c92;
}

/******************************************************************************
/*-------------------------------- Media Queries ------------------------------
/*****************************************************************************/

@media screen and (max-width: 959px) {
  .sidebar-btn {
    display: flex;
  }
  .main {
    padding-top: 150px;
    padding-left: 0px;
  }
}